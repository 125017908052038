import * as XLSX from 'xlsx';

export function downloadExcel(columns: string[], data: any[], fileName: string = 'data.xlsx'): void {
    // Directly use `data` as it already contains objects with keys matching column names
    const worksheet = XLSX.utils.json_to_sheet(data, { header: columns });

    // Create a workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a binary Excel file and trigger download
    XLSX.writeFile(workbook, fileName);
}
