import { Delete, Add } from "@mui/icons-material";
import { Autocomplete, Box, Button, Card, CardActions, CardContent, CircularProgress, IconButton, Stack, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@pankod/refine-mui";
import { useState } from "react";
import { useNavigate } from '@pankod/refine-react-router-v6'
import axios from "axios";
import AddProductDialog from "components/addProductDialog";
import { useNotification, useTable } from '@pankod/refine-core';
import { url } from "assets/url";

type Product = { _id: string; name: string; sellPrice: number, quantity: number, productCode: String, productDescription: string };

const Bids = () => {
  const navigate = useNavigate();

  const { tableQueryResult: productsTableQueryResult }
    = useTable({ resource: "Products" })
  const products = productsTableQueryResult.data?.data ?? [];
  const { open } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [phone, setPhone] = useState('');
  const [date, setDate] = useState('');
  const [address, setAddress] = useState('');
  const [chosenProducts, setChosenProducts] = useState<{ product: Product; chosenQuantity: number, }[]>([]);
  const [finalPrice, setFinalPrice] = useState(0);

  const [productName, setProductName] = useState<string>('');
  const [productCode, setProductCode] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(0);
  const [firstPrice, setFirstPrice] = useState(0);

  const [fakeId, setFakeId] = useState(0);


  const handleCartesianClick = async () => {
    setIsLoading(true)
    await axios.post(`${url}/Bids/Quotation`,
      {customerName, chosenProducts, phone, date, address}, {
        withCredentials: true,
        responseType: 'blob',
      }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'הצעה.pdf');
      document.body.appendChild(link);
      link.click();
      setIsLoading(false)
      //navigate('/')
    }).catch((error) => {
      console.error(error);
    });
  };
  const handleCartesianClick2 = async () => {
    setIsLoading(true)
    await axios.post(`${url}/Bids/QuotationWithVat`,
      {customerName, chosenProducts, phone, date, address}, {
        withCredentials: true,
        responseType: 'blob',
      }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'הצעה.pdf');
      document.body.appendChild(link);
      link.click();
      setIsLoading(false)
      //navigate('/')
    }).catch((error) => {
      console.error(error);
    });
  };

  const handleCustomerNameChange = (e : any) => {
    setCustomerName(e.target.value);
  }

  const handleClickAddProduct = (product: Product, chosenQuantity: number,) => {
    for (const p of chosenProducts) {
      if (p.product._id === product._id) {
        return;
      }
    }
    setChosenProducts([...chosenProducts, { product, chosenQuantity, }]);
    setFinalPrice(finalPrice + (product.sellPrice * chosenQuantity));
  };
  
  const handleClickAddProduct2 = () => {
    const newProduct: Product = {
      _id: String(fakeId),
      name: productName,
      sellPrice: firstPrice,
      quantity: quantity,
      productCode: productCode,
      productDescription: '',
    };
    setChosenProducts([...chosenProducts, { product: newProduct, chosenQuantity: quantity }]);
    setFinalPrice(finalPrice + (firstPrice * quantity));
    setFakeId(fakeId + 1)
    resetProductValues();
  };
  

  // Reset product inputs
  const resetProductValues = () => {
    setProductName('');
    setProductCode('');
    setQuantity(0);
    setFirstPrice(0);
  }

  const handleRemoveProductButton = (p : any) => {
    setChosenProducts(chosenProducts.filter(product => product.product._id !== p.product._id))
    setFinalPrice(finalPrice - (p.product.sellPrice* p.chosenQuantity));
  }

  const createBidRequste = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${url}/Bids`, {
        customerName,
        chosenProducts,
        phone,
        date,
        address,
      }, { withCredentials: true, });
      if (response.status === 200) {
        open?.({
            type: 'success',
            message: 'Bid updated successfully!',
            undoableTimeout: 6,
            description: '',
        });
        navigate('/Bids');
    } else {
        open?.({
            type: 'error',
            message: `Failed to update bid: ${response.statusText}`,
            undoableTimeout: 6,
            description: '',
        });
    }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ direction: 'rtl' }}>

    <Typography fontSize={34} fontWeight='bold' sx={{textAlign: 'right'}}>הצעת מחיר</Typography><br />
    <Stack   
      direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      spacing={6}
    >
    <Stack direction="row">
      <Typography style={{ direction: 'rtl', fontWeight: 'bold', fontSize: '22px', marginLeft: 4 }}>לכבוד:</Typography>
      <TextField id="outlined-basic" variant="outlined" onChange={handleCustomerNameChange} inputProps={{ style: { fontSize: '18px' } }}/>
    </Stack>
    <Stack direction="row">
      <Typography style={{ direction: 'rtl', fontWeight: 'bold', fontSize: '22px', marginLeft: 4 }}>טל:</Typography>
      <TextField id="outlined-basic" variant="outlined" onChange={(e:any) => setPhone(e.target.value) } inputProps={{ style: { fontSize: '18px' } }}/>
    </Stack>
    <Stack direction="row">
      <Typography style={{ direction: 'rtl', fontWeight: 'bold', fontSize: '22px', marginLeft: 4 }}>תאריך:</Typography>
      <TextField id="outlined-basic" variant="outlined" onChange={(e:any) => setDate(e.target.value) } inputProps={{ style: { fontSize: '18px' } }}/>
    </Stack>
    <Stack direction="row">
      <Typography style={{ direction: 'rtl', fontWeight: 'bold', fontSize: '22px', marginLeft: 4 }}>כתובת:</Typography>
      <TextField id="outlined-basic" variant="outlined" onChange={(e:any) => setAddress(e.target.value) } inputProps={{ style: { fontSize: '18px' } }}/>
    </Stack>
    </Stack> <br/>

    <Box sx={{ display: { xs: 'none', sm: 'block' }}}>
      <Typography sx={{ fontWeight: "bold", fontSize: 22, }}>רשימת המוצרים</Typography>
      {openDialog && <AddProductDialog  dialogState={setOpenDialog} yesFunction={handleClickAddProduct}/>}
      <TableContainer>
        <Table sx={{ minWidth: 650, }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right',}}>שם מוצר</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right',}}>מספר פריט</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right',}}>מחיר</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right',}}>כמות</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right',}}>סכום</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right',}}>הסרה</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chosenProducts.map((p) => (
              <TableRow key={p.product._id}>
                <TableCell sx={{fontSize: 19, textAlign: 'right' }}>{p.product.name}</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right' }}>{p.product.productCode}</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right' }}>{p.product.sellPrice} ₪</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right' }}>{p.chosenQuantity}/{p.product.quantity}</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right' }}>{p.chosenQuantity * p.product.sellPrice} ₪</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right' }}><Button onClick={() => handleRemoveProductButton(p)}
                  sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}}><Delete/></Button></TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell sx={{ textAlign: 'right' }}>
                <Autocomplete
                  freeSolo
                  disablePortal
                  id="combo-box-demo"
                  options={products}
                  getOptionLabel={(option:any) => option.name}
                  value={{ name: productName }}
                  onChange={(event, newProduct) => {
                    const typedProduct = newProduct as any;
                    if (typedProduct) {
                      setProductName(typedProduct?.name);
                      if (productCode === '' || !productCode) {
                        setProductCode(typedProduct?.productCode);
                      }
                    }
                  }}
                  onInputChange={(event, productName) => setProductName(productName)} 
                  sx={{ direction: 'rtl', minWidth: 250 }}
                  renderInput={(params) => <TextField {...params} label="מוצר"  style={{ direction: 'rtl', }}/>}
                />
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Autocomplete
                  freeSolo
                  disablePortal
                  id="combo-box-demo"
                  options={products}
                  getOptionLabel={(option:any) => option?.productCode}
                  value={{ productCode: productCode }}
                  onChange={(event, newProduct) => {
                    if(newProduct) {
                      const typedProduct = newProduct as any;
                      setProductCode(typedProduct.productCode);
                      if (productName === '') {
                        setProductName(typedProduct.name);
                      }
                    }
                  }}
                  onInputChange={(event, productCode) => setProductCode(productCode)} 
                  renderInput={(params) => <TextField {...params} label="מספר פריט"  style={{ minWidth: 150, direction: 'rtl', unicodeBidi: 'plaintext' }}/>}
                />
              </TableCell>
              <TableCell sx={{ textAlign: 'right', }}>
                <TextField
                  label="מחיר"
                  name="firstPrice"
                  type="number"
                  value={firstPrice}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      setFirstPrice(value);
                    }
                  }}
                  inputProps={{ min: 0, step: 0.0000000000000000000001 }}
                />
              </TableCell>
              <TableCell sx={{ textAlign: 'right', }}>
                <TextField
                  label="כמות"
                  name="quantity"
                  type="number"
                  value={quantity}
                  onChange={(quantity:any) => setQuantity(quantity.target.value)}
                  inputProps={{ min: 0 }}
                />
              </TableCell>
              <TableCell sx={{ textAlign: 'right', fontSize: '18px', fontWeight: 'bold' }}>{firstPrice * quantity}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}><IconButton style={{ backgroundColor: '#08D205', color: 'white' }} onClick={() => handleClickAddProduct2()}><Add/></IconButton></TableCell>
            </TableRow>

          </TableBody>

        </Table>
      </TableContainer><br/>
    </Box>
    <Box sx={{ display: { xs: 'block', sm: 'none' }}}>
        <Stack direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1.5}>
          {chosenProducts.map((p) => (
            <Card sx={{ boxShadow: 5, borderRadius: 5, border: 1 }} key={Number(p.product._id)}>
            <CardContent>
              <Typography fontSize={24} fontWeight='bold' sx={{textAlign: 'right'}}>
                {p.product.name}
              </Typography>
              <Stack direction='row-reverse' justifyContent="space-between"> 
                <Typography variant="body2">
                  <Stack spacing={0.5} direction="column" justifyContent="flex-start" alignItems='flex-end'>
                    <Typography sx={{fontSize: '21px'}}>מחיר ליחד: {String(p.product.sellPrice)}₪</Typography>
                    <Typography sx={{fontSize: '21px'}}>כמות: {String(p.chosenQuantity)}</Typography>
                    <Typography sx={{fontSize: '21px'}}>מחיר לכמות: {Number(p.chosenQuantity) * Number(p.product.sellPrice)}</Typography>
                  </Stack>
                </Typography>
                <CardActions>
                <Button onClick={() => handleRemoveProductButton(p)}
                  sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}}><Delete/></Button>                   
                </CardActions>
              </Stack>
            </CardContent>
          </Card>
          ))}
          </Stack>
        <br/>
      </Box>
    <Box sx={{ direction: 'rtl', textAlign: 'left' }}>
      <Typography sx={{fontSize: '22px', fontWeight: 'bold'}}>סה"כ: {Number(finalPrice).toFixed(2)}</Typography><br/>
    </Box>
    <Stack
      direction='row-reverse'
      justifyContent="space-between"
      alignItems="flex-start">
        { isLoading ? <CircularProgress size={30} color="inherit" /> : <Button variant="contained" color="success" onClick={createBidRequste}>שמירת ההצעה</Button> }
      {isLoading ? <CircularProgress size={30} color="inherit" /> : 
        (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button onClick={handleCartesianClick} variant="contained" color="info">
              קובץ פשוט
            </Button>
            <Button onClick={handleCartesianClick2} variant="contained" color="info">
              קובץ כולל מע"ם
            </Button>
          </Box>
        )
      }
    </Stack>
  </Box>
  );
}

export default Bids