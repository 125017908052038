import { Add, Delete, Edit, } from '@mui/icons-material';
import { useDelete, useLogout, } from '@pankod/refine-core';
import { Box, Button, CircularProgress, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@pankod/refine-mui'
import { useNavigate } from '@pankod/refine-react-router-v6';
import { url } from 'assets/url';
import axios from 'axios';
import Dialog from 'components/dialog';
import { ColorModeContext } from 'contexts';
import { useCallback, useContext, useEffect, useState } from 'react'

function Bids() {
  const { mode } = useContext(ColorModeContext);
  const tableHead = mode === "light" ? "#DFDFDF" : " #333333";
  const textColor = mode === "light" ? "black" : "white";
  
  const { mutate } = useDelete();
  const { mutate: mutateLogout } = useLogout();
  const [currentId, setCurrentId] = useState(false);

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [bids, setBids] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);

  const fetchBids = async (pageNum = 1) => {
    setLoading(true);
    try {
        const response = await axios.get(`${url}/Bids`, {
          params: { page: pageNum, limit: 20 },
          withCredentials: true,
        });
        const { bids: fetchedBids, totalBids } = response.data;
        setBids((prevBids) => pageNum === 1 ? fetchedBids : [...prevBids, ...fetchedBids]);
        setHasMore(fetchedBids.length > 0 && bids.length < totalBids);
      } catch (error:any) {
        console.error(error);
        if (error?.response && error?.response.status === 401) {
          mutateLogout() 
          navigate('/login') 
        }
      } finally {
        setLoading(false);
      }
  };

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100 && !loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
      setScrollLoading(true);
    }
}, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, loading, hasMore]);
  useEffect(() => {
    fetchBids(page);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ page, ]);
  useEffect(() => {
    setScrollLoading(false);
  }, [bids]);
  
  const handleDeleteBid = (id: String) => {
    mutate(
      {
        resource: "Bids",
        id: id as string,
      },
      {
        onSuccess: () => {
          fetchBids(1);
          setCurrentId(false);
          navigate("/Bids");
        },
      },
    );
  };

  return (
    <Box>
      <Stack direction="row-reverse"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={12}>
        <Typography fontSize='26px' color={textColor}  fontWeight='bold'> הצעות מחיר </Typography>
        <Button sx={{boxShadow: 3, borderRadius: 10}} variant="contained" endIcon={<Add/>}
          onClick={() => navigate('/Bids/create')}>הוספת הצעת מחיר</Button>
      </Stack><br/>

      <TableContainer sx={{ display: { xs: 'none', sm: 'block' }, direction: 'rtl', }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: tableHead }}>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>מס"</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>שם</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>תאריך</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>פעולות</TableCell>
            </TableRow>
          </TableHead>
          {loading && page === 1 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX: 'hidden', overflowY: 'hidden' }}>
            <CircularProgress
              disableShrink
              size={60}
              thickness={5}
              style={{ marginTop: '20px' }}
            />
          </Box>
        ) : (
          <TableBody>
            {bids.map((bid, index) => (
              <TableRow key={bid._id}>
                <TableCell align='center' sx={{fontSize: 20, color: textColor, wordBreak: 'break-word', }}>{index}</TableCell>
                <TableCell align='center' sx={{fontSize: 20, color: textColor, wordBreak: 'break-word', }}>{bid.customerName}</TableCell>
                <TableCell align='center' sx={{fontSize: 20, color: textColor, wordBreak: 'break-word', }}>{bid.date}</TableCell>
                <TableCell align='center' sx={{fontSize: 20, color: textColor, wordBreak: 'break-word', }}>
                  <Tooltip title={ <Typography variant="h6">עריכה</Typography>}>
                    <IconButton color='info' onClick={() => navigate(`/Bids/edit/${bid._id}`)}>
                      <Edit/>
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={ <Typography variant="h6">מחיקה</Typography>}>
                    <IconButton color='error' onClick={() => setCurrentId(bid._id)}>
                      <Delete/>
                    </IconButton>
                  </Tooltip>
                </TableCell>
                {currentId === bid._id && <Dialog title={`? האם אתה רוצה למחוק הצעה מספר: ${index}`} dialogState={setCurrentId} yesFunction={handleDeleteBid} itemId={bid._id}/>}
              </TableRow>
            ))}
          {scrollLoading && <CircularProgress size={30} color="inherit" />}
          </TableBody>
        )}
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Bids