import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { Stack, FormHelperText, TextField } from "@pankod/refine-mui";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { url } from 'assets/url';
import { CircularProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FilesDialog({ dialogState, itemId }) {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState('');
    
  const DeepExplainInvoice = () => {
    setLoading(true);
    axios.post(`${url}/Orders_History/createPDFFileDeepExplain`, {itemId, notes }, {
    withCredentials: true,
    responseType: 'blob',
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'הצעת_מחיר.pdf');
      document.body.appendChild(link);
      link.click();
      dialogState(false);
    }).catch((error) => {
      console.error(error);
    }).finally(() => { setLoading(true); });
  };

  const ExplainInvoice1 = () => {
    setLoading(true);
    axios.post(`${url}/Orders_History/createPDFFileExplainInvoice1`, {itemId, notes }, {
    withCredentials: true,
    responseType: 'blob',
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'הצעת_מחיר.pdf');
      document.body.appendChild(link);
      link.click();
      dialogState(false);
    }).catch((error) => {
      console.error(error);
    }).finally(() => { setLoading(true); });
  };

  const SimpleInvoice = () => {
    setLoading(true);
    axios.post(`${url}/Orders_History/createPDFSimpleInvoice`, {itemId, notes }, {
    withCredentials: true,
    responseType: 'blob',
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'הצעת_מחיר.pdf');
      document.body.appendChild(link);
      link.click();
      dialogState(false);
    }).catch((error) => {
      console.error(error);
    }).finally(() => { setLoading(true); });
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
        open={dialogState}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        maxWidth={'lg'}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textAlign: 'center' }}>מסמכים</DialogTitle>
        <DialogContent>
          {loading ? (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <CircularProgress size={24} />
            </Stack>
          ) : (
            <Stack 
              direction="column"
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Button sx={{ bgcolor: '#58ACFB', color: 'white' }} onClick={DeepExplainInvoice}>מסמך בהסבר עמוק</Button>
                <Button sx={{ bgcolor: 'primary.main', color: 'white' }} onClick={ExplainInvoice1}>מסמך 1</Button>
                <Button sx={{ bgcolor: '#8A4FFF', color: 'white' }} onClick={SimpleInvoice}>מסמך פשוט</Button>
              </Stack>
              <div>
                <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', }}>
                  הערות
                </FormHelperText>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  color="info"
                  variant="outlined"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value) }
                  inputProps={{ style: { fontSize: '17px', direction: 'rtl', fontWeight: 'bold' } }}
                />
              </div><br/><br/>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button sx={{ bgcolor: '#FF4040', color: 'white' }} onClick={() => dialogState(false)}>סגור</Button>
        </DialogActions>
      </Dialog>
  );
}
