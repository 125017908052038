import { Edit, Payment, Delete, Payments, Visibility } from '@mui/icons-material';
import { useLogout, useShow, useTable, useNotification, } from '@pankod/refine-core'
import { useNavigate } from '@pankod/refine-react-router-v6'
import { Box, Button, Card, CircularProgress, Stack, Typography, CardContent, CardActions, TableCell, TableHead, TableBody, TableRow, TableContainer, Table, Tabs, Tab, Chip, } from '@pankod/refine-mui';
import { useState, useContext } from 'react';
import MenuDialog from 'components/menuDialog';
import axios from 'axios';
import ConfirmationDialog from 'components/ConfirmationDialog';
import AddPaymentModal from 'components/AddPaymentModal';
import { ColorModeContext } from "contexts";
import { url } from 'assets/url';
import { downloadExcel } from 'utils/excelGen';

const CustomerDetails = () => {
  const { mode } = useContext(ColorModeContext);
  const navigate = useNavigate();
  const { queryResult } = useShow();
  const { open, } = useNotification();
  const { mutate: mutateLogout } = useLogout();
  const { data, isLoading, isError, isRefetching, refetch  } = queryResult;

  const backgroundColor = mode === "light" ? "#343434" : "#FFFFFF";
  const tableHead = mode === "light" ? "#D9D9D9" : " #333333";

  const customer = data?.data ?? [];  
  const allPayments = data?.data.allPayments ?? [];
  
  const [dialogStatus, setDialogStatus] = useState(false);
  const [paymentDialogStatus, setPaymentDialogStatus] = useState(false);
  const [openMenu, setOpenMenu] = useState(false)

  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredOrdersPayed, setFilteredOrdersPayed] = useState([]);
  const [totalFilteredOrdersPrice, setTotalFilteredOrdersPrice] = useState([]);
  const [value, setValue] = useState(0);
  const [addPaymentStatus ,setAddPaymentStatus] = useState(false);
  const [deletedPayment ,setDeletedPayment] = useState(false);

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  }
  const { tableQueryResult } = useTable({
    resource: `Customers/CustomerOrders/${customer._id}`
  })
  const orders = tableQueryResult.data?.data ?? [];  


  if(isLoading || isRefetching) return <center><CircularProgress disableShrink /></center>
  
  if(isError) { 
    mutateLogout() 
    navigate('/login') 
  }

  const handleClickCartesianGetPaid = () => {
    if (filteredOrders.length !== 0) {
      axios.post(`${url}/Customers/CartesianGetPayed`, 
      {customerId: customer._id, filteredOrders},
      {withCredentials: true, responseType: 'json'}).then((res) => {
        setPaymentDialogStatus(false);
        open?.({
          type: 'success',
          message: 'התעדכן שהכטיסט שולמה בשלמותה',
          undoableTimeout: 6,
          description: "התעדכן בהצלחה",
        });
        navigate('/Customers');
      }).catch((error: any) => {
        console.error('Error:', error);
        open?.({
          type: "error",
          message: error.response?.data?.message || "שגיאה במחיקת המוצר",
          undoableTimeout: 6,
          description: "שגיאה",
        });
      });
    }
  }

  const handleDialogYesClick = (data:any) => {
    axios.post(`${url}/Customers/CartesianMenu`, {customerId: customer._id, data}, {
      withCredentials: true,
      responseType: 'json',
    }).then((res) => {
      const { filteredOrders, totalPrice, payed } = res.data;
      setFilteredOrders(filteredOrders);
      setFilteredOrdersPayed(payed);
      setTotalFilteredOrdersPrice(totalPrice);
      setOpenMenu(true)
      setDialogStatus(false)

      // Download PDF of the Cartesian
      axios.post(`${url}/Customers/Cartesian`,
          {customerId: customer._id, data}, {
          withCredentials: true,
          responseType: 'blob',
      }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'כרטיסיה.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch((error:any) => {
        let errMsg = error.response?.data.message || 'שגיאה בהורדת הקובץ'
        open?.({
          type: "error",
          message: errMsg,
          undoableTimeout: 6,
          description: 'שגיאה',
        });
      });
      
    }).catch((error:any) => {
      let errMsg = error.response?.data.message || 'שגיאה'
      open?.({
        type: "error",
        message: errMsg,
        undoableTimeout: 6,
        description: 'שגיאה',
      });
    });
  };

  const handleDeletePayment = (item:any) => {
    axios.delete(`${url}/Customers/${customer?._id}/Payments/delete/${item}`,
    {withCredentials: true, responseType: 'json'}).then((res) => {
      setDeletedPayment(false);
      refetch();
    })
  }

  return (
    <Box>
      <Stack direction="row-reverse"
            justifyContent="space-between"
            alignItems="flex-start">
        <Typography fontSize='28px' fontWeight='bold'>
          {customer?.name}
        </Typography>
        <Stack direction={{ sm: 'column', md: 'row' }} spacing={2} justifyContent='space-between'>
          <Button onClick={() => navigate(`/Customers/edit/${customer?._id}`)}
              sx={{boxShadow: 3, borderRadius: 10}} variant="contained" startIcon={<Edit/>}>עריכה</Button>
          <Button onClick={() => setDialogStatus(true)} sx={{ display: { xs: 'none', sm: 'none', md: 'block' },
               boxShadow: 3, borderRadius: 10, bgcolor: '#577BFC', color: '#FFFFFF'}} variant="contained" startIcon={<Payment/>}>כרטיסיה</Button>
          <Button onClick={() => setAddPaymentStatus(true)} sx={{mt: 1, boxShadow: 3, borderRadius: 10, bgcolor: '#577BFC', color: '#FFFFFF'}}
             variant="contained" startIcon={<Payments/>}>הוספת תשלום</Button>
        </Stack>
      </Stack><br/>
      <Stack
        direction={{ sm: 'column', md: 'row-reverse'}}
        justifyContent="space-between"
        alignItems='flex-end'>
        <Typography sx={{fontSize: '22px', fontWeight: 'bold'}}>שם: {customer?.name}</Typography>
        <Typography sx={{fontSize: '22px', fontWeight: 'bold'}}>{customer?.phoneNumber} :מספר טל</Typography>
        {customer?.customerNumber && <Typography sx={{fontSize: '22px', fontWeight: 'bold'}}>{customer?.customerNumber} :ח.פ</Typography>}
        {customer?.address && <Typography sx={{fontSize: '22px', fontWeight: 'bold'}}>כתובת: {customer?.address}</Typography>}
        {(customer?.dept > 0 && <Typography sx={{bgcolor:'#FA8A79', fontSize: '22px', fontWeight: 'bold'}}>{Number(customer?.dept).toFixed(2)} :חוב</Typography>) || 
        (customer?.dept === 0 && <Typography sx={{bgcolor:'#A6FD92', fontSize: '22px', fontWeight: 'bold'}}>{customer?.dept} :חוב</Typography>) }
        <Typography sx={{fontSize: '23px', fontWeight: 'bold'}}>{customer?.allOrders.length} :הזמנות</Typography>
        {(customer?.credit > 0 && <Typography sx={{bgcolor:'#FBAC7A', fontSize: '22px', fontWeight: 'bold', borderRadius:'10px'}}>{Number(customer?.credit).toFixed(2)} :זיכוי</Typography>) || 
        (customer?.credit === 0 && <Typography sx={{bgcolor:'#ADF79C', fontSize: '22px', fontWeight: 'bold', borderRadius:'10px'}}>{customer?.credit} :זיכוי</Typography>) }
      </Stack><br/>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="basic tabs example"
        variant="fullWidth"
        centered
        sx={{ direction: 'rtl' }}
      >
        <Tab label="קניות" sx={{ fontSize: '20px', fontWeight: 'bold', color: backgroundColor }}/>
        <Tab label="תשלומים" sx={{ fontSize: '20px', fontWeight: 'bold', color: backgroundColor }}/>
      </Tabs>
      <TabPanel value={value} index={0}>

        {/* ========== Orders PC display ========== */}
        <Box sx={{ display: { xs: 'none', md: 'block' }}}>
          {!openMenu && ( 
          <TableContainer sx={{ direction: 'rtl', }}>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow sx={{ bgcolor: tableHead }}>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21 }}>מספר הזמנה</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21 }}>זמן ההזמנה</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21 }}>סטטוס</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21 }}>זיכוי</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21 }}>פעולות</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.length === 0 && <h3>אין הזמנות</h3>}
                {orders.slice().reverse().map((order) => (
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center', fontSize: 20 }}>{order.invoicingNum.toString().padStart(6, '0')}</TableCell>
                      <TableCell sx={{ textAlign: 'center', fontSize: 20 }}>{new Date(order.Time).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {order.ifPayed ? (
                          <Chip label="שולם" sx={{ fontSize: '19px', bgcolor: '#99FA60',  }} />
                        ) : (
                          <Chip label="לא שולם" sx={{ fontSize: '19px', bgcolor: '#FA8A79', borderRadius: '10px' }} />
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Chip
                          label={order.credit > 0 ? order.credit : "0"}
                          sx={{
                            fontSize: 20,
                            bgcolor: order.credit > 0 ? (mode === 'light' ? '#FFDAB9' : '#FF8C00') : 'inherit',
                            borderRadius: '10px'
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                          <Button color="primary" onClick={() => navigate(`/Orders_History/show/${order._id}`)}>
                            <Visibility />
                          </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>)}
        </Box>

        {/* ========== Orders Phone display ========== */}
        <Stack spacing={1} direction='column-reverse' sx={{ mt: 1, }}>
          {!openMenu && orders.map((order) => (
            <Card sx={{ boxShadow: 5, borderRadius: '17px', border: 1, display: { xs: 'block', sm: 'block', md: 'none' }, }} key={order._id}>
              <CardContent>
                <Typography fontSize='21px' fontWeight='bold' textAlign={'right'}>
                  הזמנה מס": {order.invoicingNum.toString().padStart(6, '0')}
                </Typography>
                <Stack direction='row-reverse' justifyContent="space-between">
                  <Stack direction="column" justifyContent="flex-start">
                    <Typography fontSize='20px' fontWeight='bold' textAlign={'right'}>{
                      new Date(order.Time).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })
                    }</Typography>
                    {(order.credit > 0 && <Typography align='center' fontWeight='bold' sx={{fontSize:'20px', bgcolor:'#FBAC7A', borderRadius:'10px'}}>{order.credit} :זיכוי</Typography>)}
                    <Typography fontSize='20px' fontWeight='bold' textAlign={'right'}>מוצרים: {order.products.length}</Typography>
                    {(!order.ifPayed && (<Typography align='center' fontWeight='bold' sx={{fontSize:'22px', bgcolor:'#FA8A79', borderRadius:'10px'}}>לא שולם </Typography>))
                     || (order.ifPayed && (<Typography align='center' fontWeight='bold' sx={{fontSize:'22px', bgcolor:'#99FA60', borderRadius:'10px'}}>שולם </Typography>))}

                  </Stack>
                  <CardActions>
                    <Button variant="text" sx={{ fontSize:18 ,background: '#475BE8', borderRadius: '30px', color: '#ffffff', boxShadow: 2}}
                    onClick={() => navigate(`/Orders_History/show/${order._id}`)}>פרטים</Button>
                  </CardActions>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>

        {/* ========== Cartesian ========== */}
        {openMenu && <Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{bgcolor: '#232323'}}>          
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מחיר לכמות</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>כמות</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מ.אחרי הנחה</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>הנחה</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מחיר ליחיד</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מס' פריט</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מוצר</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders.map((order : any) => (
                  <>
                  {order.products.map((product: any) => (
                    <TableRow key={product._id}>               
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{Number(product.chosenQuantity * product.product.sellPrice * (1 - (product.dedication/100))).toFixed(2)} ₪</TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{product.chosenQuantity}</TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{Number((1 - (product.dedication/100)) * product.product.sellPrice).toFixed(2)}₪</TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{product.dedication}</TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{product.product.sellPrice}₪</TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}} style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}>{product.product.productCode}</TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}} style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}>{product.product.name}</TableCell>
                    </TableRow>
                  ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer><br/>
          <Box style={{ textAlign: 'right' }}>
            <Typography fontSize='25px' style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}>סכ"ה כולל הנחות: {Number(totalFilteredOrdersPrice).toFixed(3)}₪</Typography>
            <Typography fontSize='25px' style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}>מע"מ: {(Number(totalFilteredOrdersPrice) * 0.17).toFixed(3)}₪</Typography>
            <Typography fontSize='25px' style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}>סכ"ה לתשלום: {(Number(totalFilteredOrdersPrice) * 1.17).toFixed(3)}₪</Typography>
            <Typography fontSize='25px' style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}>שולם: {Number(filteredOrdersPayed).toFixed(3)}₪</Typography>
            <Typography fontSize='25px' style={{ direction: 'rtl', unicodeBidi: 'plaintext', fontWeight: 'bold', }}>נשאר לתשלום: {(Number(totalFilteredOrdersPrice) * 1.17 - Number(filteredOrdersPayed)).toFixed(3)}₪</Typography>
          </Box>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Button onClick={() => {
              console.log("start");
              let excelData: any[] = [];
              filteredOrders.forEach((order: any) => {
                order.products.forEach((product: any) => {
                  excelData.push({
                    'מחיר לכמות': (product.chosenQuantity * product.product.sellPrice * (1 - product.dedication / 100)),
                    'כמות': product.chosenQuantity,
                    'מ.אחרי הנחה': ((1 - product.dedication / 100) * product.product.sellPrice),
                    'הנחה': product.dedication,
                    'מחיר ליחיד': product.product.sellPrice,
                    'מק"ט': product.product.productCode,
                    'מוצר': product.product.name,
                  });
                });
              });
              console.log(excelData)
              downloadExcel(
                ['מחיר לכמות', 'כמות', 'מ.אחרי הנחה', 'הנחה', 'מחיר ליחיד', 'מק"ט', 'מוצר'],
                excelData,
                'מוצרים.xlsx'
              );
              console.log("end");
            }} sx={{ fontSize: '16px', bgcolor: "green", color: 'white', borderRadius: 3 }}>קובץ אקסל</Button>
            <Button onClick={() => setPaymentDialogStatus(true)} sx={{boxShadow: 3, borderRadius: 10,
              bgcolor: '#FC2E2E', fontSize: '18px'}} variant="contained">לסמן שהכרטיסת שולמה בשלמותה</Button>
            <Button onClick={() => setOpenMenu(false)} sx={{ fontSize: '16px', bgcolor: 'gray', color: 'white', borderRadius: 3 }}>סגור</Button>
          </Stack>
        </Box>}
      </TabPanel>
    

      {/* ========== Payments PC display ========== */}
      <TabPanel value={value} index={1}>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, direction: 'rtl',}}>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow sx={{ bgcolor: tableHead }}>
                <TableCell sx={{fontSize: '20px', fontWeight: 'bold', textAlign: 'right'}}>סוג תשלום</TableCell>
                <TableCell sx={{fontSize: '20px', fontWeight: 'bold', textAlign: 'right'}}>בנק</TableCell>
                <TableCell sx={{fontSize: '20px', fontWeight: 'bold', textAlign: 'right'}}>חשבון</TableCell>
                <TableCell sx={{fontSize: '20px', fontWeight: 'bold', textAlign: 'right'}}>תאריך</TableCell>
                <TableCell sx={{fontSize: '20px', fontWeight: 'bold', textAlign: 'right'}}>סכום</TableCell>
                <TableCell sx={{fontSize: '20px', fontWeight: 'bold', textAlign: 'right'}}>איש</TableCell>
                <TableCell sx={{fontSize: '20px', fontWeight: 'bold', textAlign: 'right'}}>פעולות</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allPayments.length === 0 && <h3>אין תשלומים</h3>}
              {allPayments.map((payment: any) => (
               <TableRow key={payment.id}>

                  <TableCell sx={{fontSize: '19px', textAlign: 'right'}}>
                    {payment.paymentType === 'check' ? `ציק - ${payment.payment?.number}` : 
                    payment.paymentType === 'bankTransfer' ? 'העברה בנקאית' : 
                    payment.paymentType === 'cash' ? 'מזומן' : 'ויזה (Visa)'}
                  </TableCell>
                  <TableCell sx={{fontSize: '19px', textAlign: 'right'}}>
                    {payment.payment?.bank && payment.payment?.branch ? `${payment.payment.bank} - ${payment.payment.branch}` : "אין"}
                  </TableCell>
                  <TableCell sx={{fontSize: '19px', textAlign: 'right'}}>{payment.payment?.account || "אין"}</TableCell>
                  <TableCell sx={{fontSize: '19px', textAlign: 'right'}}>{payment.payment?.date?.day}/{payment.payment?.date?.month}/{payment.payment?.date?.year}</TableCell>
                  <TableCell sx={{fontSize: '19px', textAlign: 'right'}}>{payment.payment?.price}₪</TableCell>
                  <TableCell sx={{fontSize: '19px', textAlign: 'right'}}>{payment.payment?.person || "אין"}</TableCell>
                  <TableCell sx={{fontSize: '19px', fontWeight: 'bold', textAlign: 'right'}}>
                    <Button sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}} onClick={()=>setDeletedPayment(payment._id)}><Delete /></Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        
        {/* ========== Payments Phone display ========== */}
        <Box sx={{ mt: 1, display: { xs: 'block', sm: 'block', md: 'none' } }}>
          {allPayments.length === 0 && <h3>אין תשלומים</h3>}
          {allPayments.map((payment:any) => (
            <Stack direction='column-reverse'>
            <Card key={payment.id} sx={{ boxShadow: 5, borderRadius: '17px', marginBottom: 2, border: 1}}>
              <CardContent>
                <Typography sx={{ fontSize: 20, textAlign: 'right', fontWeight: 'bold', direction: 'rtl' }}>
                  {payment.paymentType === 'check' ? `ציק - ${payment.payment?.number}` : 
                  payment.paymentType === 'bankTransfer' ? 'העברה בנקאית' : 
                  payment.paymentType === 'cash' ? 'מזומן' : 'Unknown Payment Type'}
                </Typography>

                <Stack direction="row-reverse" justifyContent="space-between">
                  <Stack spacing={0.5} direction="column" justifyContent="flex-start">
                    {payment.payment?.bank && (
                      <Typography sx={{ fontSize: 18, textAlign: 'right', direction: 'rtl' }}>
                        בנק: {payment.payment.bank} - {payment.payment.branch}
                      </Typography>
                    )}
                    {payment.payment?.account && (
                      <Typography sx={{ fontSize: 18, textAlign: 'right', direction: 'rtl' }}>
                        חשבון: {payment.payment.account}
                      </Typography>
                    )}
                    <Typography sx={{ fontSize: 18, textAlign: 'right', direction: 'rtl' }}>
                      תאריך: {payment.payment?.date?.day}/{payment.payment?.date?.month}/{payment.payment?.date?.year}
                    </Typography>
                    {payment.payment?.person && (
                      <Typography sx={{ fontSize: 18, textAlign: 'right', direction: 'rtl' }}>
                        איש: {payment.payment.person}
                      </Typography>
                    )}
                    <Typography sx={{ fontSize: 19, textAlign: 'right', direction: 'rtl' }}>
                      סכום: {payment.payment?.price}₪
                    </Typography>
                  </Stack>
                  <CardActions>
                    <Button sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}} onClick={() => setDeletedPayment(payment._id)}><Delete /></Button>
                  </CardActions>
                </Stack>
              </CardContent>
            </Card>
            {deletedPayment === payment._id && <ConfirmationDialog dialogState={setDeletedPayment} yesFunction={() => handleDeletePayment(payment.id)} message={`האם אתה רוצה למחוק תשלום מהחשבון ${customer?.name} ששולם בתאריך: ${payment.payment?.date?.day}/${payment.payment?.date?.month}/${payment.payment?.date?.year}`}/>}</Stack>
          ))}
        </Box>
      </TabPanel>
      {dialogStatus && <MenuDialog dialogState={setDialogStatus} yesFunction={handleDialogYesClick}/>}
      {paymentDialogStatus && <ConfirmationDialog yesFunction={handleClickCartesianGetPaid} dialogState={setPaymentDialogStatus} message={"האם אתה בטוח שרוצה לסמן ההזמנות ששולמו בשלמותם ?"}/>}
      {addPaymentStatus && <AddPaymentModal title={`הוספת תשלום מ- ${customer?.name}`} dialogState={setAddPaymentStatus} yesFunction={()=> refetch()} url={`${url}/Customers/${customer?._id}/Payments/create`} />}
  </Box>
  )
}

export default CustomerDetails

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, } = props;

  if (value === index) {
    // Display the content of this tab panel when it's active
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {children}
      </div>
    );
  } else {
    // Hide the content when it's not active
    return null;
  }
}